import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from '../../router'
import Header from './header'
import CommonContext from '../../context/common-context'
import Footer from './footer'


class Layout extends Component {
  constructor(props) {
    super(props)

    this.toggleHeaderWhite = (args) => {
      this.setState({
        headerWhite: args
      })
    }

    this.state = {
      headerWhite: false,
      toggleHeaderWhite: this.toggleHeaderWhite
    }
  }

  componentDidMount() {
  }

  render() {
    const { headerWhite, toggleHeaderWhite } = this.state
    return (
      <CommonContext.Provider value={{ headerWhite, toggleHeaderWhite }}>
        <Router>
          <Header key="container-key-header" />
          <div className="content-container" key="container-key-content">
            <Routes />
          </div>
          <Footer className="common-footer" key="container-key-footer" />
        </Router>
      </CommonContext.Provider>
    )
  }
}

export default Layout
