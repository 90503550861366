import React, { Component } from 'react'
import { createUuid } from '../../../asset/js/util'
import langData from '../../../lang/en/about'

const pageName = 'chairman'

export default class Chairman extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: langData
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className="about-content chairman-content">
        <h1 className="about-name">{data.tabComponentNames[pageName].name}</h1>
        <div className="content-text">
          {data.chairman.list.map((item) => (
            <div className="block" key={createUuid()}>
              <p className="block-tit">{item.title}</p>
              <div className="block-overview">
                {item.overview.map((texts) => (
                  <p className="block-text" key={createUuid()}>{texts.text}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
