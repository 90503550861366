import React, { Component } from 'react'
import {
  Route, Switch, Redirect
} from 'react-router-dom'

import Home from '../pages/home'
import About from '../pages/about'
import Services from '../pages/service'
import News from '../pages/news'
import NewsInfo from '../pages/newsInfo'
import Contact from '../pages/contact'

export default class AllRouter extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Switch>
        <Route path="/" component={Home} exact />
        <Route
          path="/index"
          component={() => <Redirect to="/" />}
        />
        <Route path="/about/:type(chairman|history|team|awards|profile)" component={About} />
        <Route path="/about" exact component={() => <Redirect to="/about/chairman" />} />
        <Route path="/services" component={Services} exact />
        <Route path="/news" component={News} exact />
        <Route path="/news/:id" component={NewsInfo} exact />
        <Route path="/contact" component={Contact} />
      </Switch>
    )
  }
}
