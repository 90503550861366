import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Mask from '../mask'
import { createRandom } from '../../../asset/js/util'
import '../style/popup.less'

export default class Modal extends Component {
  _remove() {
    const { destory, uuid } = this.props
    if (destory) {
      destory(uuid)
    }
  }

  _renderBtn() {
    const { option } = this.props
    let { btn } = option
    btn = btn && btn.length ? btn.reverse() : []
    return btn && btn.length ? (
      <div className="modal-btns">
        {btn.map((item) => (
          <button
            type="button"
            key={`btn-key-${createRandom()}`}
            className="btn"
            onClick={() => {
              this._remove()
              if (item.callback) { item.callback() }
            }}
          >
            {item.text}
          </button>
        ))}
      </div>
    ) : null
  }

  render() {
    const { option } = this.props
    const btnDoms = this._renderBtn()
    return (
      <div className="popup-wrapper modal-wrapper">
        <Mask visible close={this._remove.bind(this)} />
        <div className={`popup-content modal-content ${option.type === 'large' ? 'model-large-content' : ''}`}>
          <div className="modal-header">
            <span className="title">{option.title}</span>
            {option.closeBtn ? (
              <i
                className="iconfont iconclose"
                onClick={this._remove.bind(this)}
              />
            ) : null}
          </div>
          <div className="modal-con" dangerouslySetInnerHTML={{ __html: option.content }} />
          {btnDoms}
        </div>
      </div>
    )
  }
}

Modal.defaultProps = {
  destory: null,
  uuid: '',
  option: {},
  btn: []
}

Modal.propTypes = {
  destory: PropTypes.func,
  uuid: PropTypes.string,
  option: PropTypes.objectOf(PropTypes.any),
  btn: PropTypes.arrayOf(PropTypes.any)
}
