import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { Player } from 'video-react';
import langData from '../../lang/en/news'
import { requestApi } from '../../api/config'
import { getUrlParam } from '../../asset/js/util'
import './index.less'
// eslint-disable-next-line
// import '/node_modules/video-react/dist/video-react.css'
import '../../../node_modules/video-react/dist/video-react.css'

const playImage = require('../../asset/images/news/news_play_icon.svg')
const newsVideoImage = require('../../asset/images/news/news_video_image.jpg')
// const newsImage = require('../../asset/images/news/news_img02@2x.png')

export default class News extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isPlay: false,
      data: langData,
      list: [],
      pageNo: 1,
      pageSize: 11,
      totalPage: 1,
      isPlayNews: false,
      w: 0,
      h: 0
    }
    this.videoRef = React.createRef()
  }

  componentDidMount() {
    const { location } = this.props // eslint-disable-line
    const curPage = getUrlParam('page', location.search) // eslint-disable-line
    this.setState({
      pageNo: curPage || 1,
      w: document.documentElement.clientWidth || 0,
      h: document.documentElement.clientHeight || 0
    }, () => {
      this._getData()
    })
  }

  _getData() {
    const { pageSize, pageNo } = this.state
    requestApi('NEWS_LIST', { pageNo, pageSize }).then((res) => {
      this.setState({ list: res.list, pageNo: res.pageNo, totalPage: res.totalPage })
    })
  }

  handlePlay() {
    this.videoRef.current.play()
    this.setState({ isPlay: true })
  }

  handlePageClick(data) {
    const pageIndex = data.selected;
    this.setState({ pageNo: pageIndex + 1 }, () => {
      this._getData()
    })
  }

  handlePlayerNewsVideo() {
    this.setState({ isPlayNews: true })
  }

  handleCloseNewsVideo() {
    this.player.pause()
    this.setState({ isPlayNews: false })
  }

  handleStateChange(state) {
    // copy player state to this component's state
    // eslint-disable-next-line
    this.setState({ player: state });
    // const { player } = this.state
    // console.log(player)
  }

  render() {
    const {
      isPlay, data, list, totalPage, isPlayNews, w, h
    } = this.state
    return (
      <div className="news-container">
        <div className="news-video">
          <video className="video-player" x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" preload="none" loop ref={this.videoRef}>
            <source src="https://www.ihongsen.com/images/video/senmiao-news.mp4" type="video/mp4" x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" />
            <track kind="captions" />
          </video>
          <div className="play" onClick={this.handlePlay.bind(this)} style={{ display: isPlay ? 'none' : 'block' }}>
            <img src={playImage} alt="play" />
          </div>
          <div className="down">
            <i className="icon iconfont icondown" />
          </div>
        </div>
        <div className="container">
          <div className="news-list">
            <p className="title">{data.index.title}</p>
            <div className="row">
              {/* 视频 */}
              <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4" onClick={this.handlePlayerNewsVideo.bind(this)}>
                <div className="news-item">
                  <img src={newsVideoImage} alt={data.newsVideo.title} />
                  <p className="date">
                    {data.newsVideo.date}
                    &nbsp;
                  </p>
                  <p className="name">{data.newsVideo.title}</p>
                </div>
              </div>
              {list.map((item) => (
                <Link
                  to={`/news/${item.id}`}
                  key={`news-item-key-${item.id}`}
                  className="col-xs-6 col-sm-6 col-md-4 col-lg-4 news-item-col"
                >
                  <div className="news-item">
                    <img src={item.newsCover} alt={item.newsTitle} />
                    <p className="date">{item.createDate}</p>
                    <p className="name">{item.newsTitle}</p>
                  </div>
                </Link>
              ))}
            </div>
            <div className="paginate-wrapper">
              <ReactPaginate
                onPageChange={this.handlePageClick.bind(this)}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                previousLabel={'<'}
                nextLabel=">"
              />
            </div>
          </div>
        </div>
        {isPlayNews ? (
          <div className="video-play-wrapper">
            <Player
              ref={(player) => {
                this.player = player;
              }}
              className="react-video"
              playsInline
              src="https://www.ihongsen.com/images/video/Senmiao-news-2.mp4"
              fluid={false}
              width={w}
              height={h}
            />
            <i className="icon iconfont iconclose" onClick={this.handleCloseNewsVideo.bind(this)} />
          </div>
        ) : null}
      </div>
    )
  }
}
