const data = {
  page1: {
    title: 'About Senmiao Technology',
    text: 'Senmiao Technology Limited ("Senmiao Technology" or “the Company”) is an innovative, fast-growing service provider of automobile transactions and related services. The Company was incorporated in the State of Nevada, United States, on June 8, 2017.',
    text2: 'The common stock of the company started trading on Nasdaq under the ticker symbol “AIHS” on March 16, 2018.',
    text3: 'Our auto business provides financial solutions by connecting auto dealers, financial institutions and consumers. With an extensive network in Sichuan Province, particularly in the mega-cities of Chongqing and Chengdu, our integrated service line capitalizes on the expanding ride-hailing market in second and third tier cities in mainland China. We strive to deliver best-in-class services to ride-hailing drivers.',
    text4: 'On the road, every decision counts. We are committed to provide the most uncompromising service, based on advanced technology and diversified financial services. We provide our customers with easier access and cost-effective high-quality services, helping them reach their financial goals and achieve stability to focus on what’s ahead of them. Through mergers and acquisitions with high-quality peers, we are committed to becoming a nationally respected company in automobile services across China.',
    text5: 'On the road, every decision counts. We are committed to provide the most uncompromising service, based on advanced technology and diversified financial services. We provide our customers with easier access and cost-effective high-quality services, helping them reach their financial goals and achieve stability to focus on what’s ahead of them. Through mergers and acquisitions with high-quality peers, we are committed to becoming a nationally respected company in automobile services across China.'
  },
  page2: {
    title: 'Full Service Auto Business',
    autoText: 'The Auto Business operates solely in China, principally in the cities of Chengdu and Changsha. This core business is operated by Hunan Ruixi and Chengdu Yicheng, both automobile finance leasing companies, and Sichuan Jinkailong, which is an automobile transaction and financial services provider for the online ride-hailing market.',
    autoT1: 'The “Auto Business” or Automobile Transaction and Related Services include:',
    autoT2: 'Facilitation of automobile transaction and financing on the purchase of cars by connecting prospective ride-hailing drivers with financial institutions (“Auto Financing and Transaction Facilitation”)',
    autoT3: 'Automobile sales procurement of new cars from dealerships and reselling to customers in the automobile financing facilitation business (“Auto Sales”)',
    autoT4: 'Automobile financing solutions through financing leases direct to customers (“Auto Financing”)',
    overview: 'Senmiao Technology Limited ("Senmiao Technology" or “the Company”) is an innovative, fast-growing service provider of automobile transactions and related services. The Company was incorporated in the State of Nevada, United States, on June 8, 2017.',
    more: 'more',
    list: [
      {
        name: 'Auto Financing and Transaction Facilitation',
        image: '',
        overview: '<p>Leveraging the growing popularity of ride-hailing services in China, we facilitate the auto financing transactions between the ride-hailing drivers and financial institutions. As of the date of this Report, all the ride-hailing drivers we service are affiliated Didi Chuxing Technology Co., Ltd., a major transportation company operating the largest ride-hailing platform in China (“Didi”). Our services simplify the transaction process for both the Didi drivers and the financial institutions. Specifically, our facilitation services include purchase services and management and guarantee services.</p><h4 class="mt20">1. Purchase services</h4><p class="mt10">Purchase services cover a wide range of services provided to Didi drivers during the process of an automobile financing transaction, including but not limited to, credit assessment, preparation of financing application materials, assistance with closing of financing transactions, license and plate registration, payment of taxes and fees, purchase of insurance, installment of GPS devices, ride-hailing driver qualification and other administrative procedures. Our service fees are based on the sales price of the automobiles and relevant services provided.</p><h4 class="mt20">2. Management and guarantee services</h4><p class="mt10">The management and guarantee services are provided to Didi drivers after the delivery of automobiles, covering (i) management services including, without limitation, ride-hailing driver training, assisting with purchase of insurances, insurance claims and after-sale automobile services, handling traffic violations and other consulting services; and (ii) guarantee services for the obligations of Didi drivers under their financing arrangement with financial institutions. Our management and guarantee fees are based on the costs of our services and the results of our credit assessment of the automobile purchasers.</p>'
      },
      {
        name: 'Auto Sales',
        image: '',
        overview: '<p>As we are targeting to sell cars to Didi, Our subsidiaries procures new cars of model and specification acceptable to Didi. Our subsidiaries typically sets up periodic procurement plans based on the estimated transaction volume of automobiles to which we are going to provide purchase services. Our subsidiaries will then mark up the price and sell the cars to the ride-hailing drivers who are typically customers in our auto financing facilitation services.</p>'
      },
      {
        name: 'Auto Financing',
        image: '',
        overview: '<p>In our self-operated financing, we act as a lessor and a customer (i.e., Didi driver) acts as a lessee. We offer to the lessee a selection of automobiles that were purchased by us in advance. The lessee will choose the desirable automobile to be purchased and enter into a financing lease with us. During the term of the financing lease, the lessee will have use rights with respect to the automobile. We will obtain title to the automobile upfront and retain such title during the term of the financing lease, as lessor. At the end of the lease term, the lessee will pay a minimal price and obtain full title to the automobile after the financing lease is repaid in full. In connection with the financing lease, the lessee will enter into a service agreement with us.</p>'
      }
    ]
  },
  page3: {
    title: 'Online Lending Services',
    image: '',
    t1: 'ihongsen',
    t2: 'We operate an online lending platform through Sichuan Senmiao in the PRC which facilitates loan transactions between Chinese investors and individual and SME borrowers.',
    t3: 'With an extensive network in Sichuan and Hunan Province, particularly in the mega-cities of Chengdu and Changsha, our integrated service line capitalizes on the expanding ride-hailing market in second and third tier cities in mainland China. We strive to deliver best-in-class services to ride-hailing drivers and customers.',
    t5: 'We operate an online lending platform through Sichuan Senmiao in the PRC which facilitates loan transactions between Chinese investors and individual and SME borrowers. Through our platform, we offer access to credit for borrowers and attractive investment returns for investors.  From the acquisition of our online platform in September 2016 through June 30, 2019, we have facilitated loan transactions in an aggregate amount of over RMB823 million (approximately US$120 million).  We currently conduct our business operations exclusively in China, and all of our investors and borrowers are located in China.',
    nt1: 'In October 2019, Sichuan Senmiao closed its online lending platform business that facilitated loan transactions between Chinese investors, individuals and SME borrowers after an in-depth review of its overall business strategy.',
    nt2: 'Going forward, the Company will focus all its resources on its fast growing Full Service Auto Business.',
    nt3: 'Why invest in Senmiao Technology',
    nt4: 'The expanding automobile leasing and online ride-hailing markets in China create opportunities for stronger growth.',
    nt5: 'We offer more attractive automobile financing solutions and services to our customers by cooperating with the leading online ride-hailing service platform in China, multiple financial institutions, and other third-parties.',
    nt6: 'Ownership of a financial leasing license is a key competitive strength among the rapidly developing automobile financial leasing industry.',
    nt7: 'We achieve excellent risk and credit control through continuous innovation and R&D to strengthen our risk management and operations, led by an experienced risk management team.'
  }
}
export default data
