import React from 'react';
import Layout from './components/layout'
import './asset/styles/style.less'


function App() {
  return (
    <Layout />
  );
}

export default App;
