const data = {
  t1: {
    title: 'Company Contact',
    t1: 'Yiye Zhou, IR Director',
    t2: 'Email: edom333@ihongsen.com',
    t3: 'Phone: +86 28 6155 4399'
  },
  t2: {
    title: 'Investor Relations',
    t1: 'Carolyne Sohn (The Equity Group Inc.)',
    t2: 'Email: csohn@equityny.com',
    t3: 'Phone: +1 415-568-2255'
  },
  t3: {
    title: 'Address',
    t1: '16F, Shihao Square, Middle Jiannan Blvd. High-Tech Zone, Chengdu, Sichuan, People’s Republic of China 610000'
  }
}
export default data
