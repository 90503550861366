import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import CommonContext from '../../../context/common-context'
import Nav from '../nav'
import './index.less'

const logo = require('../../../asset/images/common/logo.png')
const logoWhite = require('../../../asset/images/common/logo-white.png')

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: false,
      isIndexPage: false,
      isToggleUp: false,
      isToggleDown: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    /* eslint-disable react/prop-types */
    const { history } = this.props
    this.pathname = history.location.pathname
    this._setDefaultHeaderWhite()
    history.listen((e) => {
      // 监听滚动，调整Header
      this.handleScroll(e.pathname)
      // _setDefaultHeaderWhite()
      this._setDefaultHeaderWhite()
      // 路由变化时，关闭移动端的遮罩和菜单Nav
      this.setState({ toggle: false })
    })
  }

  componentWillUpdate() {
    // const { history } = this.props
    // this.pathname = history.location.pathname
  }

  onChangeNav(state) {
    this.setState({ toggle: state })
  }

  _setDefaultHeaderWhite() {
    if (this.pathname === '/contact' || this.pathname === '/services') {
      this.myContext.toggleHeaderWhite(true)
    }
    if (this.pathname === '/' || this.pathname === '/index') {
      this.setState({ isIndexPage: true })
    } else {
      this.setState({ isIndexPage: false })
    }
  }

  handleScroll() {
    const { history } = this.props
    this.pathname = history.location.pathname
    if (this.pathname === '/contact' || this.pathname === '/services') {
      this.myContext.toggleHeaderWhite(true)
      return true
    }
    /* eslint-disabled prefer-destructuring */
    const { scrollTop } = document.documentElement
    if (scrollTop > 300) {
      // 当滚动距离大于顶部 300 时，判断如果Header还不是白色就改变成白色
      // 且如果Header已经是白色，那么就判断滚动方向是上还是下进行Header的Toggle处理
      if (this.myContext.headerWhite === false) {
        this.myContext.toggleHeaderWhite(true)
      } else if (this.prevScrollTop <= scrollTop) {
        this.setState({ isToggleUp: true, isToggleDown: true })
      } else {
        this.setState({ isToggleUp: false, isToggleDown: false })
      }
    } else if (this.myContext.headerWhite === true) {
      this.myContext.toggleHeaderWhite(false)
      this.setState({ isToggleUp: false })
    }
    this.prevScrollTop = scrollTop
    return false
  }

  _setContextObj(args) {
    this.myContext = args
  }

  handleToggleNav() {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
  }

  render() {
    const {
      toggle, isIndexPage, isToggleUp, isToggleDown
    } = this.state
    console.log(isToggleDown);
    return [
      <CommonContext.Consumer key="header-top-key">
        {(cont) => {
          this._setContextObj(cont)
          const { headerWhite } = cont
          return (
            <div className={`container-fluid flex-between header-container ${headerWhite ? 'header-white' : ''} ${isIndexPage ? 'index-header' : 'other-header'} ${headerWhite && !isIndexPage ? 'shadow-header' : ''} ${isToggleUp ? 'toggle-top-header' : ''}`}>
              <Link to="/" className="flex-cen logo">
                <img className="logo-image" src={headerWhite ? logo : logoWhite} alt="logo" />
              </Link>
              <Nav className="hidden-sm hidden-xs" />
              <i className={`hidden-lg hidden-md nav-toggle icon iconfont iconlist ${headerWhite ? 'color-black' : toggle ? 'color-black' : 'color-white'}`} onClick={this.handleToggleNav.bind(this)} />
            </div>
          )
        }}
      </CommonContext.Consumer>,
      <Nav className="hidden-lg hidden-md" key="header-toggle-key" toggle={toggle} onChangeNav={this.onChangeNav.bind(this)} />
    ]
  }
}

export default withRouter(Header)
