const data = {
  index: {
    title: 'News'
  },
  newsVideo: {
    title: 'Transformation of Senmiao Technology Limited to automobile business.',
    date: ''
  }
}
export default data
