import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { nav as navData } from '../../../lang/en/common'
import { createUuid } from '../../../asset/js/util'

export default class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleCloseNav() {
    const { onChangeNav } = this.props
    if (onChangeNav) {
      onChangeNav(false)
    }
  }

  _renderNavList() {
    const list = []
    navData.list.forEach((item) => {
      list.push(
        <li className="nav-item" key={`nav-key-${createUuid()}`}>
          {item.isThirdLink
            ? <a href={item.link} target="_black">{item.name}</a>
            : <Link to={item.link} target={item.isTarget ? '_black' : '_self'}>{item.name}</Link>}
        </li>
      )
    })
    return list
  }

  render() {
    const { className, toggle } = this.props
    const navDom = this._renderNavList()
    return (
      <div className={className}>
        <div className={`nav-wrapper ${toggle ? 'nav-wrapper-toggle' : ''}`}>
          <div className="nav-list">
            <ul>{navDom}</ul>
          </div>
        </div>
        <div className={`nav-toggle-mask ${toggle ? 'nav-toggle-mask-show' : ''}`} onClick={this.handleCloseNav.bind(this)} />
      </div>
    )
  }
}

Nav.defaultProps = {
  className: '',
  toggle: false,
  onChangeNav: null
}

Nav.propTypes = {
  className: PropTypes.string,
  toggle: PropTypes.bool,
  onChangeNav: PropTypes.func
}
