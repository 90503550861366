import React, { Component } from 'react'
import { createUuid } from '../../asset/js/util'
import langData from '../../lang/en/services'

import './index.less'

export default class Services extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: langData
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className="container service-container">
        <div className="content-text">
          <h1 className="max-title">{data.title}</h1>
          <div className="block clearfix around-box">
            <div className="fl">
              <img src={data.t1.image} alt="image1" />
            </div>
            <div className="fr">
              <p className="around-tit">{data.t1.title}</p>
              <p className="block-text">{data.t1.text}</p>
            </div>
          </div>
          <div className="block mt30">
            {data.t2.list.map((texts) => {
              if (texts.type === 'title') {
                return (<p className="block-text block-text-bold" key={createUuid()}>{texts.text}</p>)
              }
              return (<p className="block-text" key={createUuid()}>{texts.text}</p>)
            })}
            <div className="options">
              {data.t2.option3Item.map((item) => (<p className="block-text option-item" key={createUuid()}>{item.text}</p>))}
            </div>
          </div>
          <div className="block clearfix around-box">
            <div className="fl">
              <p className="around-tit">{data.t3.title}</p>
              <p className="block-text">{data.t3.text}</p>
            </div>
            <div className="fr">
              <img src={data.t3.image} alt="image1" />
            </div>
          </div>
          <div className="block clearfix around-box">
            <div className="fl">
              <img src={data.t4.image} alt="image1" />
            </div>
            <div className="fr">
              <p className="around-tit">{data.t4.title}</p>
              <p className="block-text">{data.t4.text}</p>
              <p className="block-text pad-circle-text">{data.t4.text2}</p>
              <p className="block-text pad-circle-text">{data.t4.text3}</p>
              <p className="block-text pad-circle-text">{data.t4.text4}</p>
            </div>
          </div>
        </div>
        <div className="content-text">
          <h1 className="max-title">{data.title2}</h1>
          <p className="block-tit" />
          <p className="block-text">{data.text2}</p>
          <p className="block-text">{data.text3}</p>
        </div>
      </div>
    )
  }
}
