import React, { Component } from 'react'
import { createUuid } from '../../../asset/js/util'
import langData from '../../../lang/en/about'

const pageName = 'awards'

export default class Awards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: langData
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className="about-content awards-content">
        <h1 className="about-name">{data.tabComponentNames[pageName].name}</h1>
        <div className="content-text">
          <div className="row awards-box">
            <div className="clearfix awards-item">
              {data.awards.list.map((item) => (
                <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4" key={createUuid()}>
                  <img className="image" src={item.image} alt={item.text} />
                  <p className="text">{item.text}</p>
                  <p className="overview">{item.overview}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
