import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { footer as langData } from '../../../lang/en/common'
import { createUuid } from '../../../asset/js/util'

import './index.less'

// const ihsImg = require('../../../asset/images/footer/bottom_ihslogo01_hover@2x.png')
const rxImg = require('../../../asset/images/footer/bottom_rxlogo01_hover@2x.png')
const jklImg = require('../../../asset/images/footer/bottom_jkllogo01_hover@2x.png')
const ycImg = require('../../../asset/images/footer/bottom_yclogo01_hover@2x.png')
const linkImg = require('../../../asset/images/footer/bottom_link_icon.svg')
const xxImg = require('../../../asset/images/footer/bottom_xxlogo01_hover@2x.jpg')
const knjcImg = require('../../../asset/images/footer/bottom_kljclogo01_hover@2x.jpg')

export default class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    langData.partner = [
      // { name: 'ihongsen.com', image: ihsImg, link: 'https://www.ihongsen.com/' },
      { name: 'RUIXI FINANCIAL LEASING', image: rxImg, link: 'http://www.51ruixi.com/' },
      { name: 'JIN KAI LONG QI CHE', image: jklImg, link: 'http://www.jklqc.com/' },
      { name: 'YICHENG FINALCE LEASING', image: ycImg, link: '' },
      { name: 'KUNENGJIEChENG', image: knjcImg, link: '' },
      { name: 'XIXINGTIANXIA', image: xxImg, link: '' }
    ]

    this.footerRef = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      const { onRefFooterDom } = this.props
      if (onRefFooterDom) {
        onRefFooterDom(this.footerRef)
      }
    }, 20)
  }

  _renderPartner() {
    const list = []
    langData.partner.forEach((item) => {
      const partner = (
        <a href={item.link} target="_black" className="partner-item" key={`footer-partner-key-${createUuid()}`}>
          <img className="partner-image" src={item.image} alt={item.name} />
          <img className="partner-icon" src={linkImg} alt="link" />
          <div className="partner-mask" />
        </a>
      )
      const partner2 = (
        <div className="partner-item" key={`footer-partner-key-${createUuid()}`}>
          <img className="partner-image" src={item.image} alt={item.name} />
          <div className="partner-mask" />
        </div>
      )
      const partnerResult = item.link ? partner : partner2
      list.push(
        partnerResult
      )
    })
    return list
  }

  render() {
    const partnerDom = this._renderPartner()
    const { className } = this.props
    return (
      <div className={`container-fluid flex-cen footer-container ${className}`} ref={this.footerRef}>
        <div className="clearfix partner">
          {partnerDom}
        </div>
        <div className="flex-between footer-info">
          <div className="footer-left">
            <p className="tel">{langData.tel}</p>
            <p className="email">{langData.email}</p>
            <p className="address">{langData.address}</p>
          </div>
          <div className="footer-right">
            <a href="http://www.beian.miit.gov.cn" target="__blank">{langData.record}</a>
            {/* <p>{langData.record2}</p> */}
            <p>{langData.copyright}</p>
          </div>
        </div>
      </div>
    )
  }
}

Footer.defaultProps = {
  className: '',
  onRefFooterDom: null
}

Footer.propTypes = {
  className: PropTypes.string,
  onRefFooterDom: PropTypes.func
}
