import axios from 'axios'
import qs from 'qs'

export const ERROR_OK = 0
// export const HOST = 'http://test.supergan.cn:8888'
export const HOST = 'http://47.99.143.249:8089'
export const MAIN = 'http://senmiaotechir.com:8080'

export const api = {
  NEWS_LIST: '/f/news/listPage',
  NEWS_INFO: '/f/news/get'
}

export function getApiByKey(key) {
  return `${HOST}${api[key]}`
}
export const requestUrl = {
  // newsListPage: `${doMain}/f/page`,
  financialList: `${MAIN}/f/financial/listPage` // 获取财务信息列表
}
export function requestListApi(urlKey, params) {
  const url = requestUrl[urlKey]
  const data = qs.stringify({ ...params })
  return axios
    .post(url, data)
    .then((res) => res.data)
    .catch((err) => err)
}
export function requestApi(key, params) {
  return new Promise((resolve, reject) => {
    axios.post(getApiByKey(key), qs.stringify(params))
      .then((res) => {
        if (res.data.error === ERROR_OK) {
          resolve(res.data.data)
        } else {
          reject(res)
        }
      }, (err) => {
        reject(err)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
