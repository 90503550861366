export const nav = {
  list: [
    { name: 'Home', link: '/' },
    { name: 'About Us', link: '/about' },
    { name: 'Services', link: '/services' },
    { name: 'News', link: '/news' },
    {
      name: 'Investors', link: 'http://senmiaotechir.com/', isThirdLink: true, isTarget: true
    },
    { name: 'Contact', link: '/contact' }
  ]
}
export const footer = {
  partner: [],
  tel: 'Tel:  +86 28 61554399',
  email: 'E-mail:  ihs@ihongsen.com',
  address: 'Address:  16F, Shihao Square, Middle Jiannan Blvd. High-Tech Zone, Chengdu, Sichuan, People’s Republic of China 610000',
  address3: 'Address: 16F, Shihao Square, Middle Jiannan Blvd.',
  address2: 'High-Tech Zone, Chengdu, Sichuan, People’s Republic of China 610000',
  record: '备案号：蜀ICP备19018073号-1',
  record2: '公安机关网站备案图标及编号：  川公网安备 51019002001164号',
  copyright: 'Copyright © 2016-2019 SENMIAO TECHNOLOGY LIMITED. All rights reserved.'
}
