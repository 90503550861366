const awwardsImage = require('../../asset/images/about/aboutus_awsrds01@2x.png')
const certificationsImage = require('../../asset/images/about/aboutUs_certifications@2x.png')

const teamWenxiImage = require('../../asset/images/about/aboutus_wximg01@2x.png')
const teamZxyImage = require('../../asset/images/about/aboutus_zxyimg02@2x.png')
const teamLchImage = require('../../asset/images/about/aboutus_lchimg02@2x.png')
const teamLhtImage = require('../../asset/images/about/aboutus_lhtimg02@2x.png')
const teamGjImage = require('../../asset/images/about/aboutus_gjimg02@2x.png')

const historyImage1 = require('../../asset/images/about/01.svg')
const historyImage2 = require('../../asset/images/about/02.svg')
const historyImage3 = require('../../asset/images/about/03.svg')
const historyImage4 = require('../../asset/images/about/04.svg')
const historyImage5 = require('../../asset/images/about/05.svg')
const historyImage6 = require('../../asset/images/about/06.svg')
const historyImage7 = require('../../asset/images/about/07.svg')

const tabComponentNames = {
  chairman: { key: 1, name: 'Chairman’s Message' },
  profile: { key: 2, name: 'Corporate Profile' },
  history: { key: 3, name: 'Corporate History' },
  team: { key: 4, name: 'Management Team' },
  awards: { key: 5, name: 'Awards and Certifications' }
}

const data = {
  tabHeader: [
    { id: 1, name: 'Chairman’s Message', link: '/about/chairman' },
    { id: 2, name: 'Corporate Profile', link: '/about/profile' },
    { id: 3, name: 'Corporate History', link: '/about/history' },
    { id: 4, name: 'Management Team', link: '/about/team' },
    { id: 5, name: 'Awards and Certifications', link: '/about/awards' }
  ],
  tabContent: [
    { id: 1, component: null },
    { id: 2, component: null },
    { id: 3, component: null },
    { id: 4, component: null },
    { id: 5, component: null }
  ],
  tabComponentNames,
  chairman: {
    list: [
      {
        title: 'Creating a new era.',
        overview: [
          { text: 'Miles ahead in automobile transaction services.' },
          { text: '“China is like a time machine into the future. With advances in technology driving most businesses today, it is crucial that we discover new business models and innovative ways to stay ahead of the competition. Looking to diversify our business and explore new markets for more growth and profitability, we acquired 60% of the equity interest of Hunan Ruixi Financial Leasing Co., Ltd. (“Hunan Rjuixi”) in the fourth quarter of 2018. This acquisition gave us a unique opportunity to capitalize on the growing ride-hailing market in second and third tier cities in China, like Chengdu and Changsha. Through this acquisition, we entered into the automobile transaction and related services, as well as the automobile leasing industries.Our revenue has grown exponentially since the acquisition, to US$15,655,575 million for the fiscal year ended March 31, 2020 from US$2,921,063 in the prior year.' },

          { text: 'During the year we facilitated and serviced 1,315 new automobile purchases compared to 311 in the prior year as we transitioned fully to the automobile transaction business.' },
          { text: 'The auto business provides financial solutions by connecting auto dealers, financial institutions and consumers. Our customers are mostly existing and prospective ride-hailing drivers affiliated with Didi Chuxing Technology Co., Ltd. (“Didi”), a major transportation network company that operates the largest ride-hailing platform in China. Over 95% of the Company’s revenue comes from the auto business. In the first quarter of 2020, ending on June 30, revenues of our auto business doubled compared to the fourth quarter of 2019, to US$5,012,563, demonstrating our ability to grow the business and make it our core competency going forward.' },
          { text: 'It is about connecting the dots and understanding what our customers want and how industries are changing. We are very pleased with the continued momentum in our auto business, where we are now focusing our resources. We must always have the vision to look ahead in order to stay on top of our game.”' },
          { text: 'Mr. Xi Wen' },
          { text: 'Chairman and CEO, Senmiao Technology' }
        ]
      }
      // {
      //   title: 'Creating Fintech Advantages',
      //   overview: [
      //     { text: 'Technology has become the foundation of the online lending industry. Our focus on innovation driving by science and technology enhanced our ability for risk management and optimal user experiences. We are committed to provide institution and individuals with a comprehensive range of financial services and products. After listing on Nasdaq in March 2018, we have invested heavily in software and hardware improvement. It is very critical for our risk control system and user protection system.' }
      //   ]
      // },
    ]
  },
  profile: {
    textList: [
      { text: 'Senmiao Technology Limited ("Senmiao Technology" or “the Company”) is an innovative, fast-growing service provider of automobile transactions and related services. The Company was incorporated in the State of Nevada, United States, on June 8, 2017.' },
      { text: 'The common stock of the company started trading on Nasdaq under the ticker symbol “AIHS” on March 16, 2018.' },
      { text: 'Our auto business provides financial solutions by connecting auto dealers, financial institutions and consumers. With an extensive network in Sichuan Province, particularly in the mega-city of Chengdu, our integrated service line capitalizes on the expanding ride-hailing market in second and third tier cities in mainland China. We strive to deliver best-in-class services to ride-hailing drivers.' },
      { text: 'On the road, every decision counts. We are committed to provide the most uncompromising service, based on advanced technology and diversified financial services. We provide our customers with easier access and cost-effective high-quality services, helping them reach their financial goals and achieve stability to focus on what’s ahead of them. Through mergers and acquisitions with high-quality peers, we are committed to becoming a nationally respected company in automobile services across China.' }
    ]
  },
  history: {
    tree2: {
      t1: { name: 'May 2014', image: historyImage1, text: 'P2P platform commenced operation' },
      t2: { name: 'March 2018', image: historyImage4, text: 'IPO on Nasdaq' },
      t3: { name: 'October 2018', image: historyImage2, text: 'Engagement with Didi Chuxing and Commenced services to online ride-hailing drivers' },
      t4: { name: 'November 2018', image: historyImage5, text: 'Acquisition of the automobile transaction and financing services business' },
      t5: { name: 'January 2019', image: historyImage3, text: 'Commenced Automobile sales' },
      t6: { name: 'March 2019', image: historyImage6, text: 'Commenced Automobile financial leasing' }
    },
    tree: [
      { name: 'May 2014', image: historyImage1, text: 'P2P platform commenced operation' },
      { name: 'March 2018', image: historyImage4, text: 'IPO on Nasdaq' },
      { name: 'October 2018', image: historyImage2, text: 'Engagement with Didi Chuxing and Commenced services to online ride-hailing drivers' },
      { name: 'November 2018', image: historyImage5, text: 'Acquisition of the automobile transaction and financing services business' },
      { name: 'January 2019', image: historyImage3, text: 'Commenced Automobile sales' },
      { name: 'March 2019', image: historyImage6, text: 'Commenced Automobile financial leasing' }
    ],
    treeCenImage: historyImage7
  },
  team: {
    list: [
      {
        name: 'Xi Wen',
        image: teamWenxiImage,
        position: 'Chief Executive Officer and Chairman',
        overview: [
          { text: 'Mr. Xi Wen currently serves as the president, chairman, chief executive officer, director and secretary of Senmiao Technology since 2017.' },
          { text: 'With over 10 years of experience in finance and investment management at financial institutions in China, Mr. Wen has also participated in the investment and establishment of several financial companies. He has a deep understanding of China’s financial institutions and the online financial industry, in particular on financial product pricing, risk management and project portfolio.' },
          { text: 'Mr. Wen has served as the executive director of Sichuan Senmiao since February 2017, leading the operations of Senmiao’s online lending platform. Prior to joining the company, from 2015 to 2017, Mr. Wen served as a director of Chenghexin Management Company, where he was responsible for overseeing the operations of the Aihongsen lending platform. He founded Chengdu Fubang Zhuoyue Investment Co. in September 2013 and served as its general manager until May 2015. From 2009 to 2013, Mr. Wen was the general manager of Chengdu Haiyuan Trading Co., Ltd. Mr. Wen holds a bachelor\'s degree in business and economics from Manchester Metropolitan University in Manchester, the United Kingdom.' }
        ]
      },
      {
        name: 'Xiaoyuan Zhang',
        image: teamZxyImage,
        position: 'Chief Financial Officer',
        overview: 'Ms. Zhang has served as CFO of Senmiao Technology since 2018. She has 8 years of auditing experience from 2010 to 2018 as the senior auditor and assurance manager at Ernst & Young Hua Ming LLP in Chengdu. Ms. Zhang is a certified public accountant in China, with expertise in tax laws, accounting and auditing standards. In 2010, she received her bachelor’s degrees in accounting and law from Southwestern University of Finance and Economics (SUFE) in Chengdu, China.'
      },
      {
        name: 'Chunhai Li',
        image: teamLchImage,
        position: 'Chief Technology Officer',
        overview: 'Mr. Li has been the CTO of Senmiao Technology since 2016 and has over 10 years of experience in online technology and development. Previously, he served as director of research and development at Beijing Teamsun Technology Co., Ltd. from 2014 to 2016. Mr. Li received a bachelor’s degree in computer science from the University of Electronic Science and Technology of China (UESTC) in Chengdu, China.'
      },
      {
        name: 'Haitao Liu',
        image: teamLhtImage,
        position: 'Chief Executive Officer of Online Lending Services',
        overview: 'Prior to joining Senmiao, Mr. Liu served as chief executive officer of Shenzhen Qianhai Tuteng Internet Financial Services Co., Ltd. from 2015 to 2018, and has over 25 years of experience in accounting and finance. Since 2018, Mr. Liu has served as chief executive officer of the Online Lending Platform Division of Senmiao Technology. He also served as deputy general manager of Chengdu High-Tech Zone Xingrui Microfinance Co., Ltd from 2012 to 2015. Mr. Liu was an adjunct lecturer at his alma mater, the Southwestern University of Finance and Economics (SUFE) in Chengdu. He received his master’s degree in EMBA (majored in Finance) from SUFE and a bachelor’s degree in business administration from Southwest Jiaotong University, Chengdu, China.'
      },
      {
        name: 'Jie Gao',
        image: teamGjImage,
        position: 'Chief Executive Officer of Automobile Transaction and Financing Services',
        overview: 'Ms. Gao has over 15 years of experience in finance and corporate management. She has served as the general manager of Hunan Ruixi Financial Leasing Company since February 2018. Prior to joining the company, she served as executive director at Guangdong Hu Mao Sheng Tang Fund Management Co., Ltd. from 2017 to 2018. Ms. Gao received her associate’s degree in hotel secretary from Hunan University of Commerce in Changsha, Hunan, China.'
      }
    ]
  },
  awards: {
    list: [
      { image: certificationsImage, text: 'Electronic Certificates: Hunan Ruixi / Chengdu Jinkailong and Didi Partnership', overview: 'These certifications confirm the partnership agreements between Hunan Ruixi, Chengdu Jinkailong and Didi Chuxing Technology Co., Ltd. (“Didi”). Didi is a major transportation network company operating the largest ride-hailing platform in mainland China.' },
      { image: awwardsImage, text: '2018 FinTech Star of Tommorrow', overview: 'Sichuan Senmiao was named “FinTech Star of Tomorrow” at the 10th Financial Technology Policymakers Conference (IFPI2018) held on October 15, 2018 in Shanghai, China.' }
    ]
  }
}

export default data
