import React, { Component } from 'react'
// import { footer as langData } from '../../lang/en/common'
import langData from '../../lang/en/contact'

import './index.less'

const logoImage = require('../../asset/images/common/logo.png')

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: langData
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className="about-content contact-content">
        <div className="top" />
        <div className="container content-text">
          <div className="row flex-between contact-info-2">
            <div className="contact-logo">
              <img className="logo-image" src={logoImage} alt="logo" />
            </div>
            <div className="contact-info-item contact-info-item-1">
              <h3 className="title">{data.t1.title}</h3>
              <p className="text">{data.t1.t1}</p>
              <p className="text">{data.t1.t2}</p>
              <p className="text">{data.t1.t3}</p>
            </div>
            <div className="contact-info-item contact-info-item-2">
              <h3 className="title">{data.t2.title}</h3>
              <p className="text">{data.t2.t1}</p>
              <p className="text">{data.t2.t2}</p>
              <p className="text">{data.t2.t3}</p>
            </div>
            <div className="contact-info-item contact-info-item-3">
              <h3 className="title">{data.t3.title}</h3>
              <p className="text">{data.t3.t1}</p>
            </div>
          </div>
          {/* <div className="row flex-between contact-info">
            <div className="flex-cen left">
              <p className="tel">{data.tel}</p>
              <p className="address">{data.address3}</p>
              <p className="address">{data.address2}</p>
            </div>
            <img className="logo-image" src={logoImage} alt="logo" />
          </div> */}
        </div>
      </div>
    )
  }
}
