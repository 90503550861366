const img1 = require('../../asset/images/services/4k-wallpaper-automobile-black-2710043@2x.png')
const img2 = require('../../asset/images/services/4k-wallpaper-automobile-black-2710043 copy@2x.png')
const img3 = require('../../asset/images/services/hunters-race-MYbhN8KaaEc-unsplash@2x.png')

const data = {
  title: 'Auto Transaction and Related Services',
  t1: {
    image: img1,
    title: 'Auto Financing and Transaction Facilitation',
    text: 'Leveraging the growing popularity of ride-hailing services in China, we facilitate the auto financing transactions between the ride-hailing drivers and financial institutions. As of October 2019, the majority of ride-hailing drivers we service are affiliated to Didi Chuxing Technology Co., Ltd. (“Didi”), a major transportation company operating the largest ride-hailing platform in China. Our services simplify the transaction process for both Didi drivers and the financial institutions. Specifically, our facilitation services include (1) Facilitation of Automobile Transactions (2) Automobile Purchase Services and (3) Management and Guarantee Services.'
  },
  t2: {
    list: [
      { text: '1. Facilitation of Automobile Transactions', type: 'title' },
      { text: 'We facilitate automobile purchase transactions between auto dealers, our cooperative third-party sales teams and the automobile purchasers, who are primarily ride-hailing drivers. We provide the sales venue and vehicle sourcing for all the transactions, then charge a facilitation fee to the dealers, third-party sales teams and automobile purchasers based on the type of vehicle chosen.' },
      { text: '2. Automobile Purchase Services', type: 'title' },
      { text: 'Automobile Purchase Services cover a wide range of services provided to Didi drivers during the process of an automobile financing transaction, including but not limited to, credit assessment, preparation of financing application materials, assistance with closing of financing transactions, license and plate registration, payment of taxes and fees, purchase of insurance, installation of GPS devices, ride-hailing driver qualification and other administrative procedures. Our service fees are based on the sales price of the automobiles and relevant services provided to ride-hailing drivers.' },
      { text: '3. Management and Guarantee Services', type: 'title' },
      { text: '¬The management and guarantee services are provided to Didi drivers after the delivery of automobiles, which includes:' }
    ],
    option3Item: [
      { text: 'Management services provide ride-hailing driver training, assistance with purchase of insurance plans, processing of insurance claims and after-sale automobile services, handling of traffic violations and other consulting services, without limitation' },
      {
        text: 'Guarantee services facilitate obligations of Didi driver under their financing arrangements with financial institutions. Our management and guarantee fees are based on the costs of our services and the results of our credit assessment of the automobile purchasers.'
      }
    ]
  },
  t3: {
    image: img2,
    title: 'Auto Sales',
    text: 'To better serve Didi drivers, our subsidiary companies manage the procurement of new vehicle models that are compliant to Didi’s transportation network. Under this framework, the companies execute periodic procurement plans based on the estimated automobile transaction volume of ride-hailing drivers, who are typically customers from our Auto Financing and Transaction Facilitation services.'
  },
  t4: {
    image: img3,
    title: 'Auto Financing',
    text: 'In our self-operated financing business, we offer a premium selection of automobiles either for purchase or on lease direct to customers based on the following process: ',
    text2: 'The lessee selects an automobile model for ride-hailing services and enters into a financing lease with the company.',
    text3: 'The company retains the title of the automobile for the entire term of the lease.',
    text4: 'At the end of the lease, the lessee can choose to purchase the automobile at a premium rate and obtain full ownership after the financing lease is repaid in full.'
  },
  title2: 'Online Lending Services',
  text2: 'In October 2019, Sichuan Senmiao closed its online lending platform business that facilitated loan transactions between Chinese investors, individuals and SME borrowers after an in-depth review of its overall business strategy.',
  text3: 'Going forward, the Company will focus all its resources on its fast growing Full Service Auto Business.'
}
export default data
