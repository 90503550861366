import React, { Component } from 'react'
import { requestApi } from '../../api/config'

import './index.less'

export default class NewsInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {}
    }
  }

  componentDidMount() {
    this._getData()
  }

  _getData() {
    const id = this.props.match.params.id // eslint-disable-line
    requestApi('NEWS_INFO', { id }).then((res) => {
      this.setState({ data: res })
    })
  }

  render() {
    const { data } = this.state
    return (
      <div className="news-info-container">
        <div className="news-info-top" style={{ 'background-image': `url(${data.newsCover})` }}>
          <div className="mask" style={{ 'background-image': `url(${data.newsCover})` }} />
          <div className="mask-opacity" />
          <div className="container">
            <p className="news-name">{data.newsTitle}</p>
            <p className="date">{data.createDate}</p>
          </div>
        </div>
        <div className="news-info-content">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div dangerouslySetInnerHTML={{ __html: data.newsContent }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
