import React, { Component } from 'react'
import propTypes from 'prop-types'

export default class Mask extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleClose(e) {
    console.log('close');
    const { close } = this.props
    if (close) {
      close()
    }
    e.stopPropagation();
  }

  render() {
    const { visible, opacity } = this.props
    const style = typeof opacity === 'number'
      ? { backgroundColor: `rgba(0,0,0,${opacity})` }
      : {}
    return visible ? <div className="popup-mask" style={style} onClick={this.handleClose.bind(this)} /> : null
  }
}

Mask.defaultProps = {
  visible: true,
  opacity: 0.2,
  close: null
}

Mask.propTypes = {
  visible: propTypes.bool,
  opacity: propTypes.number,
  close: propTypes.func
}
