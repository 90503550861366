import React, { Component } from 'react'
import langData from '../../../lang/en/about'
import { createUuid } from '../../../asset/js/util'

const pageName = 'history'

export default class History extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: langData
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className="about-content history-content">
        <h1 className="about-name">{data.tabComponentNames[pageName].name}</h1>
        <div className="content-text">
          <div className="row history-box">
            <div className="col-xs-12 col-sm-12 col-md-12 col-xs-12 history-list">
              {data.history.tree.map((item, index) => (
                <div
                  className={`history-item ${index % 2 === 0 ? 'left-item' : 'right-item'}`}
                  key={`history-key-${createUuid()}`}
                >
                  <div className="info">
                    <p className="name">{item.name}</p>
                    <p className="text">{item.text}</p>
                  </div>
                  <div className="image-box">
                    <img className="image" src={item.image} alt={item.name} />
                  </div>
                </div>
              ))}
              <div className="center-hr">
                <img src={data.history.treeCenImage} alt="cen" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
