import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import langData from '../../lang/en/about'
import Chairman from './chairman'
import Profile from './profile'
import History from './history'
import Team from './team'
import Awards from './awards'
import './index.less'

const tabContentComp = [
  { id: 1, component: <Chairman /> },
  { id: 2, component: <Profile /> },
  { id: 3, component: <History /> },
  { id: 4, component: <Team /> },
  { id: 5, component: <Awards /> }
]

export default class About extends Component {
  constructor(props) {
    super(props)
    /* eslint-disable react/prop-types */
    const n = props.match.params.type
    this.state = {
      data: langData,
      // curTabName: n,
      curTabIndex: langData.tabComponentNames[n].key,
      tabContent: tabContentComp
    }
  }

  componentDidMount() {
    /* eslint-disable react/prop-types */
    // const { history } = this.props
    // console.log(this.props);
    // history.listen(() => {
    //   console.log('change router');
    // })
  }

  handleChangeTab(data) {
    this.setState({ curTabIndex: data.id })
  }

  _renderTabHeader() {
    const { data, curTabIndex } = this.state
    const list = []
    data.tabHeader.forEach((item) => {
      list.push(
        <Link
          to={item.link}
          className={`tab-header-item ${curTabIndex === item.id ? 'active' : ''}`}
          key={`about-header-key-${item.id}`}
          onClick={this.handleChangeTab.bind(this, item)}
        >
          <span>{item.name}</span>
        </Link>
      )
    })
    return list
  }

  _renderTabContent() {
    const { curTabIndex, tabContent } = this.state
    const list = []
    tabContent.forEach((item) => {
      list.push(
        <div className={`tab-content-item ${curTabIndex === item.id ? 'active' : ''}`} key={`about-content-key-${item.id}`}>
          {item.component}
        </div>
      )
    })
    return list
  }

  render() {
    const tabHeaderDom = this._renderTabHeader();
    const tabContentDom = this._renderTabContent();
    return (
      <div className="about-container">
        <div className="top-image" />
        <div className="tab-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="flex-between tab-header-list">
                  {tabHeaderDom}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {tabContentDom}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
