import React, { Component } from 'react'
import { createUuid } from '../../../asset/js/util'
import langData from '../../../lang/en/about'

const pageName = 'profile'

export default class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: langData
    }
  }

  render() {
    console.log('lskdjf');
    const { data } = this.state
    return (
      <div className="about-content chairman-content">
        <h1 className="about-name">{data.tabComponentNames[pageName].name}</h1>
        <div className="content-text">
          <div className="block">
            <p className="block-tit" />
            {data.profile.textList.map((texts) => (
              <p className="block-text" key={createUuid()}>{texts.text}</p>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
