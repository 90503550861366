import random from 'lodash/random'

export function createRandom() {
  return parseInt(Math.random() * 1000000000, 10)
}

/**
 * UUID生成
 * @export
 */
export function getUuid() {
  return `${new Date().getTime().toString()}${random(9999, 99999)}${random(
    99,
    999
  )}`
}
