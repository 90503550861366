import React, { Component } from 'react'
import langData from '../../../lang/en/about'
import { createUuid } from '../../../asset/js/util'

const pageName = 'team'

export default class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: langData
    }
  }

  _renderOverview(args) {
    console.log(typeof args)
    // {typeof item.overview === 'string' ? item.overview : item.overview.map((text) => text)}
    if (typeof args === 'string') {
      return <p className="overview">{args}</p>
    }
    const rtn = []
    args.forEach((t) => {
      console.log(t);
      rtn.push(<p className="overview" key={createUuid()}>{t.text}</p>)
    })
    return rtn
    // return null
  }

  render() {
    const { data } = this.state
    return (
      <div className="about-content team-content">
        <h1 className="about-name">{data.tabComponentNames[pageName].name}</h1>
        <div className="content-text">
          <div className="row">
            {data.team.list.map((item) => (
              <div className="clearfix team-item" key={createUuid()}>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="team-image">
                    <img className="image" src={item.image} alt={item.name} />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                  <div className="team-info">
                    <div className="team-info-in">
                      <p className="name">{item.name}</p>
                      <p className="position">{item.position}</p>
                      <p className="hr" />
                      {this._renderOverview(item.overview)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
