import React from 'react'
import ReactDom from 'react-dom'
import assign from 'lodash/assign'
import ModalComponent from './modal'
import { createUuid as getUuid } from '../../../asset/js/util'

let modalInstance = null
const componentList = {}
/**
 * options: {
 *    title: '',
 *    content: '',
 *    closeBtn: true,
 *    maskClose: false,
 *    btn: [
 *        { btn: '确认', callback: function() {...} },
 *        { btn: '不用了', callback: function() {...}, close: true },
 *        { btn: '取消', callback: function() {...}, close: true },
 *        ...
 *   ]
 * }
 *  btn可任意多个，会以倒序形式生成，cancel会在左侧
 */
class Modal {
  /**
   * Alert提示框
   * @param {*} options {title: '', content: '', btn: '', success: ()=>{}}
   * @memberof Modal
   */
  alert(options) {
    const defaultBtn = '知道了'
    let opt = {
      title: '信息',
      content: '',
      closeBtn: true,
      maskClose: false,
      btn: [
        {
          text: options.btn || defaultBtn,
          callback: options.success,
          close: true
        }
      ]
    }
    opt = Object.assign(opt, options)
    this._loadComponent(opt)
  }

  /**
   * Confirm提示框
   * @param {*} options {title: '', content: '', btn: [], success: ()=>{}, cancel: ()=>{}}
   * @memberof Modal
   */
  confirm(options) {
    const success = '确认'
    const cancel = '取消'
    // 数据验证
    let { btn } = options
    if (btn instanceof Array && btn.splice) {
      if (btn.length !== 2) {
        btn[0] = btn[0] ? btn[0] : success
        btn[1] = btn[1] ? btn[1] : cancel
      }
    } else if (typeof btn === 'string') {
      btn = [btn || success, cancel]
    } else {
      btn = [success, cancel]
    }
    // 合并参数
    let opt = {
      title: '提示',
      content: '',
      closeBtn: true,
      maskClose: false
    }
    opt = Object.assign(opt, options)
    // 重组参数
    opt.btn = [
      {
        text: btn[0] || success,
        callback: options.success
      },
      {
        text: btn[1] || cancel,
        callback: options.cancel
      }
    ]
    this._loadComponent(opt)
  }

  /**
   * open提示框，N个按钮的弹窗，
   * @param {*} options {title: '', content: '', btn: [
   *   { text: 'button text', callback: function() {...} },
   *   { text: 'button text', callback: function() {...} },
   *   { text: 'button text', callback: function() {...} },
   *   ......
   * ]}
   * @memberof Modal
   */
  open(options) {
    // 组装数据
    let opt = {
      title: '信息',
      content: '',
      closeBtn: true,
      maskClose: false,
      btn: []
    }
    opt = assign(opt, options)
    if (!(opt.btn instanceof Array && opt.btn.splice)) opt.btn = []

    // 渲染
    this._loadComponent(opt)
  }

  // 销毁弹窗
  _destory(uuid) {
    if (componentList[uuid]) {
      const div = componentList[uuid]
      ReactDom.unmountComponentAtNode(div)
      document.body.removeChild(div)
      delete componentList[uuid]
    }
  }

  // 渲染
  _loadComponent(options) {
    const uuid = getUuid()
    const divDom = document.createElement('div')
    document.body.appendChild(divDom)
    ReactDom.render(
      <ModalComponent option={options} uuid={uuid} destory={this._destory} />,
      divDom
    )
    componentList[uuid] = divDom
  }
}

export default {
  alert(options) {
    _getMmodalInstance().alert(options)
  },
  confirm(options) {
    _getMmodalInstance().confirm(options)
  },
  open(options) {
    _getMmodalInstance().open(options)
  }
}

function _getMmodalInstance() {
  if (modalInstance === null) modalInstance = new Modal()
  return modalInstance
}
