import React, { Component } from 'react'
import 'swiper/dist/css/swiper.min.css'
import Swiper from 'swiper/dist/js/swiper.min'
import Footer from '../../components/layout/footer'
import { createUuid, isPC } from '../../asset/js/util'
import { requestListApi } from '../../api/config'
import FinancialItem from '../../components/popup/financial-item/index'

import CommonContext from '../../context/common-context'
import langData from '../../lang/en/home'
import Modal from '../../components/popup/modal'
import './index.less'

const page2Image1 = require('../../asset/images/home/index_img01@2x.png')
const page2Image2 = require('../../asset/images/home/index_img02@2x.png')
const page2Image3 = require('../../asset/images/home/index_img04@2x.png')
const page3Image1 = require('../../asset/images/home/index_quotationmark.svg')

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mySwiper: null,
      isShowNextIcon: true,
      isMobile: false,
      pcFooterHeight: 0,
      finaceList: []
    }

    langData.page2.list[0].image = page2Image1
    langData.page2.list[1].image = page2Image2
    langData.page2.list[2].image = page2Image3

    this.footerRef = React.createRef()
    this.footerSliderRef = React.createRef()
  }


  async componentDidMount() {
    const isWap = !isPC()
    const investorRes = await requestListApi('financialList', { financialInfoType: 0, pageSize: 1 })
    let finaceList = []
    if (investorRes.error === 0) {
      finaceList = investorRes.data.list
    }
    this.setState({ finaceList })
    this.footerSliderRef.current.style.height = 'auto'
    /* eslint-disable no-new */
    const mySwiper = new Swiper('.swiper-container-page', {
      direction: 'vertical',
      slidesPerView: 'auto',
      mousewheel: true,
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true
      // },
      on: {
        slideChange: () => {
          const index = mySwiper.activeIndex
          this.myContext.toggleHeaderWhite(index >= 1)
          this.setState({ isShowNextIcon: isWap ? index < 3 : index < 2 })
        }
      }
    })

    /* eslint-disable no-new */
    // new Swiper('.swiper-container-text', {
    //   // direction: 'vertical',
    //   slidesPerView: 1,
    //   spaceBetween: 100,
    //   loop: true,
    //   autoplay: true
    // })

    this.setState({
      mySwiper,
      isMobile: isWap
    })

    // add class to #root
    document.getElementById('root').setAttribute('class', 'index-root')
  }

  componentWillUnmount() {
    document.getElementById('root').setAttribute('class', '')
  }

  handleShowMoreInfoModal(data) {
    Modal.open({
      type: 'large',
      title: data.name,
      content: data.overview
    })
  }

  handleShowMoreAutoModal(data) {
    const text = `<div class="auto-modal-box"><p>${data.autoText}</p><p>${data.autoT1}</p><p class="att">${data.autoT2}</p><p class="att">${data.autoT3}</p><p class="att">${data.autoT4}</p></div>`
    Modal.open({
      type: 'large',
      title: data.title,
      content: text
    })
  }

  handleOpenPage3Modal(text) {
    const { page3 } = langData
    Modal.open({
      type: 'large',
      title: page3.t1,
      content: text
    })
  }

  _getFinaceList() {
    const { finaceList } = this.state
    console.log('sad', finaceList)
    const list = []
    if (finaceList) {
      finaceList.forEach((item) => {
        list.push(<FinancialItem data={item} key={`financial-item-key-${item.id}`} />)
      })
    }
    return list
  }

  _renderPage1() {
    const { page1 } = langData
    const finaceList = this._getFinaceList()
    console.log('sdsd', finaceList)
    return (
      <div className="home-page-1 home-video">
        <div className="home-video-bg">
          <video
            className="video-player"
            x5-video-player-type="h5"
            x-webkit-airplay="true"
            webkit-playsinline="true"
            preload="none"
            autoPlay
            loop
          >
            <source
              src="https://www.ihongsen.com/images/video/senmiao-index.mp4"
              type="video/mp4"
              x5-video-player-type="h5"
              x-webkit-airplay="true"
              webkit-playsinline="true"
            />
            <track kind="captions" />
          </video>
        </div>
        <div className="text-wrapper">
          <h3 className="title">{page1.title}</h3>
          <div className="swiper-container swiper-container-text">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div>
                  <p className="text">{page1.text5}</p>
                  <h6 className="title">Investor Presentation</h6>
                  <div>{finaceList}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderPage2() {
    const { page2 } = langData
    return (
      <div className="home-page-2">
        <h2 className="title">{page2.title}</h2>
        <p className="auto-text">{page2.autoText}</p>
        <div className="more more-auto">
          <span onClick={this.handleShowMoreAutoModal.bind(this, page2)}>
            {page2.more}
            <i className="icon iconfont iconright" />
          </span>
        </div>
        <CommonContext.Consumer>
          {(args) => {
            this._changeHeader(args)
          }}
        </CommonContext.Consumer>
        <div className="row list-box">
          {page2.list.map((item) => (
            <div
              className="col-xs-12 col-sm-12 col-md-4 col-lg-4 list-item"
              key={`page2-key-${createUuid()}`}
            >
              <img src={item.image} alt={item.name} />
              <div
                className="list-item-info"
                onClick={this.handleShowMoreInfoModal.bind(this, item)}
              >
                <h3 className="list-title">{item.name}</h3>
                <p className="list-overview" dangerouslySetInnerHTML={{ __html: item.overview }} />
                <div className="more">
                  <span>
                    {page2.more}
                    <i className="icon iconfont iconright" />
                  </span>
                </div>
                <div className="bg-wrapper" />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  _renderPage3() {
    const { page3 } = langData

    return (
      <div className="home-page-one home-page-3">
        {/* <p className="top-title title">{page3.title}</p>
        <p className="other-text">{page3.nt1}</p>
        <p className="other-text">{page3.nt2}</p> */}
        <p className="title">{page3.nt3}</p>
        <div className="why-invest">
          <div className="other-text">
            <i className="other-circle" />
            {page3.nt4}
          </div>
          <p className="other-text">
            <i className="other-circle" />
            {page3.nt5}
          </p>
          <p className="other-text">
            <i className="other-circle" />
            {page3.nt6}
          </p>
          <p className="other-text">
            <i className="other-circle" />
            {page3.nt7}
          </p>
        </div>
        {/* <div className="big-image" /> */}
        {/* <div className="flex-between text-block" onClick={this.handleOpenPage3Modal.bind(this, page3.t5)}>
          <div className="left-text-block">
            <p className="t1">{page3.t1}</p>
            <p className="t2">{page3.t2}</p>
          </div>
          <div>
            <div className="more">
              {page2.more}
              <i className="icon iconfont iconright" />
            </div>
          </div>
        </div> */}
        <div className="hr" />
        <div className="flex-between text-block">
          <img className="quote-image image-l" src={page3Image1} alt="“" />
          <div className="quote-text">
            <p className="t3">{page3.t3}</p>
          </div>
          <img className="quote-image image-r" src={page3Image1} alt="”" />
        </div>
      </div>
    )
  }

  _changeHeader(args) {
    this.myContext = args
  }


  _nextPage() {
    const { mySwiper } = this.state
    mySwiper.slideNext()
    this.myContext.toggleHeaderWhite(true)
  }

  handleFooterRef(args) {
    this.setState({ pcFooterHeight: args.current.clientHeight })
  }

  render() {
    const { isShowNextIcon, isMobile, pcFooterHeight } = this.state
    const pageDom1 = this._renderPage1()
    const pageDom2 = this._renderPage2()
    const pageDom3 = this._renderPage3()
    return (
      <div className="home-container">
        <div className="swiper-container swiper-container-page">
          <div className="swiper-wrapper">
            <div className="swiper-slide swiper-slide-1">{pageDom1}</div>
            <div className="swiper-slide swiper-slide-2">{pageDom2}</div>
            <div className="swiper-slide swiper-slide-3">{pageDom3}</div>
            <div
              className={`swiper-slide ${isMobile ? '' : 'pc-swiper-slide'}`}
              style={{ height: isMobile ? '100%' : `${pcFooterHeight}px` }}
              test={isMobile ? '100%' : `${pcFooterHeight}px`}
              ref={this.footerSliderRef}
            >
              <Footer
                className={`${isMobile ? 'index-footer' : 'index-footer pc-footer'}`}
                onRefFooterDom={this.handleFooterRef.bind(this)}
              />
            </div>
          </div>
          {/* <div className="swiper-pagination" /> */}
        </div>
        {/* 显示下一页Icon判断 */}
        {isShowNextIcon ? (
          <i
            className="next-page-wrapper icon iconfont icondown"
            onClick={this._nextPage.bind(this)}
          />
        ) : null}
      </div>
    )
  }
}

export default Home
