import React from 'react'
import PropTypes from 'prop-types'
import { createRandom } from './util'
/**
 * 获取 blob
 * url 目标文件地址
 */
function getBlob(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };

    xhr.send();
  });
}

/**
 *保存 blob
 * filename 想要保存的文件名称
 */
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}

/**
 * 下载
 * @param {String} url 目标文件地址
 * @param {String} filename 想要保存的文件名称
 */
function download(url, filename) {
  getBlob(url).then((blob) => {
    saveAs(blob, filename);
  });
}
function FinancialItem(props) {
  const { data } = props
  const ext = {
    xlsx: 'XLS',
    pdf: 'PDF',
    rtf: 'RTF',
    xbrl: 'XBRL',
    html: 'HTML'
  }
  return (
    <div className="flex-between financial-item">
      <div className="financial-info">
        {/*  <p className="date">{data.createDate}</p> */}
        <p className="name">{data.financialInfoTitle}</p>
      </div>
      <div className="financial-files">
        {data.attachmentList && data.attachmentList.length ? data.attachmentList.map((item) => (
          <a href="#" onClick={download.bind(this, item.fileUrl, item.fileName)} target="_black" key={`financial-file-key-${createRandom()}`}>
            <i className={`icon iconfont icon${ext[item.fileExtension]}`} />
          </a>
        ))
          : null}
      </div>
    </div>
  )
}

FinancialItem.defaultProps = {
  data: {}
}

FinancialItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
}

export default FinancialItem
